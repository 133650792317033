import { lazy } from 'react';
import Layout from '../layout/Layout';

// PAGES
const Home = lazy(() => import('../pages/Home'));
const Offers = lazy(() => import('../pages/Offers'));
const Services = lazy(() => import('../pages/Services'));
const Details = lazy(() => import('../pages/Details'));
const Categories = lazy(() => import('../pages/Categories'));
const Plate = lazy(() => import('../pages/Evaluate'));
const Km = lazy(() => import('../pages/Evaluate/Km'));
const Version = lazy(() => import('../pages/Evaluate/Version'));
const Client = lazy(() => import('../pages/Evaluate/Client'));
const Result = lazy(() => import('../pages/Evaluate/Result'));
const WhithoutPlate = lazy(() => import('../pages/Evaluate/WithoutPlate'));
const Financing = lazy(() => import('../pages/Financing'));
const DownPayment = lazy(() => import('../pages/Financing/DownPayment'));
const Installment = lazy(() => import('../pages/Financing/Installment'));
const FinancingResult = lazy(() => import('../pages/Financing/Result'));

const OFFERS_PATH = '/ofertas';

const homeRoute = [
  {
    path: '/',
    element: <Home />,
  },
];

const offersRoute = [
  {
    path: OFFERS_PATH + '/seminovos',
    element: <Offers />,
  },
  {
    path: OFFERS_PATH,
    element: <Categories />,
  },
  {
    path: OFFERS_PATH + '/seminovos/:dealId',
    element: <Details />,
  },
];

const serviceRoute = [
  {
    path: '/servicos',
    element: <Services />,
  },
];

const evaluateRoute = [
  {
    path: '/avaliar-veiculo/placa',
    element: <Plate />,
  },
  {
    path: '/avaliar-veiculo/sem-placa',
    element: <WhithoutPlate />,
  },
  {
    path: '/avaliar-veiculo/km',
    element: <Km />,
  },
  {
    path: '/avaliar-veiculo/versao',
    element: <Version />,
  },
  {
    path: '/avaliar-veiculo/cliente',
    element: <Client />,
  },
  {
    path: '/avaliar-veiculo/resultado-avaliacao',
    element: <Result />,
  },
];

const financingRoute = [
  {
    path: '/financiamento',
    element: <Financing />,
  },

  {
    path: '/financiamento/entrada',
    element: <DownPayment />,
  },

  {
    path: '/financiamento/parcelas',
    element: <Installment />,
  },

  {
    path: '/financiamento/resultado-financiamento',
    element: <FinancingResult />,
  },
];

const autoLayoutRoutes = [
  {
    path: OFFERS_PATH,
    element: <Layout />,
    children: [...offersRoute],
  },

  {
    element: <Layout />,
    children: [...serviceRoute],
  },

  {
    element: <Layout />,
    children: [...evaluateRoute],
  },

  {
    element: <Layout />,
    children: [...financingRoute],
  },
];

const routes = [...homeRoute, ...autoLayoutRoutes];

export default routes;
