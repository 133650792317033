import { Outlet, useLocation } from 'react-router-dom';

import Footer from 'components/src/atoms/Footer';
import Header from 'components/src/atoms/Header';
import EvaluateStepper from 'components/src/molecules/EvaluateStepper';
import background from '../assets/background/background.png';

const LayoutComponent = () => {
  const { pathname } = useLocation();

  const isEvaluateFlow = pathname?.includes('avaliar');
  const isFinancing = pathname?.includes('financiamento');
  const hasResult = pathname?.includes('resultado');

  const evaluateSteps = [
    {
      paths: ['/avaliar-veiculo/placa', '/avaliar-veiculo/sem-placa'],
      label: 1,
    },
    {
      path: '/avaliar-veiculo/versao',
      label: 2,
    },
    {
      path: '/avaliar-veiculo/km',
      label: 3,
    },
    {
      path: '/avaliar-veiculo/cliente',
      label: 4,
    },
  ];

  const financingSteps = [
    {
      path: '/financiamento',
      label: 1,
    },
    {
      path: '/financiamento/entrada',
      label: 2,
    },
    {
      path: '/financiamento/parcelas',
      label: 3,
    },
  ];

  return (
    <div
      style={{
        width: '100%',
        height: '100svh',
        background: `url(${background})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPositionY: '100px',
      }}
    >
      <Header />
      {isEvaluateFlow && !hasResult && (
        <EvaluateStepper steps={evaluateSteps} />
      )}

      {isFinancing && !hasResult && <EvaluateStepper steps={financingSteps} />}

      <Outlet />

      <Footer />
    </div>
  );
};

export default LayoutComponent;
