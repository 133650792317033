import { styled } from '@material-ui/styles';
import { StyledThemeProp } from '@sagacarmi/seminovos-design-system/core/theme';

export const HeaderWrapper = styled('header')(
  ({ theme: { palette } }: StyledThemeProp) => ({
    position: 'fixed',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 64,
    width: '100%',
    padding: '24px',
    backgroundColor: palette.neutral.black.white1,
    boxShadow: '4px 1px 2px rgba(0,0,0,0.3)',
    zIndex: 100,
  }),
);

export const LogoWrapper = styled('div')({
  width: '300px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
