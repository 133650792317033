import { FormikProps } from 'formik';
import { createContext, useContext, useMemo, useState } from 'react';
import useFinancingForm, {
  ValuesFinancing,
  initialValuesFinancing,
} from '../components/hooks/useFinancingSchema';

interface FinancingProps {
  formikFinancing: FormikProps<ValuesFinancing>;
  handleClearFormik: () => void;
  resultFinancing: number;
  setResultFinancing: (value: number) => void;
}

const FinancingContext = createContext({} as FinancingProps);

const FinancingProvider = ({ children }: any) => {
  const [resultFinancing, setResultFinancing] = useState<number>(null);

  const formikFinancing = useFinancingForm();

  const handleClearFormik = () => {
    formikFinancing.setValues(initialValuesFinancing);
  };

  const values = {
    formikFinancing,
    handleClearFormik,
    resultFinancing,
    setResultFinancing,
  };

  return (
    <FinancingContext.Provider value={values}>
      {children}
    </FinancingContext.Provider>
  );
};

const FinancingContextProvider = (Component: any) => (props: any) =>
  (
    <FinancingProvider>
      <Component {...props} />
    </FinancingProvider>
  );

const useFinancing = () => useContext(FinancingContext);

export {
  FinancingContext,
  FinancingContextProvider,
  FinancingProvider as default,
  useFinancing,
};
