import { Step, Stepper } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import * as Style from './styles';

interface StepsProps {
  paths?: string[];
  label: number;
  path?: string;
}

interface EvaluateStepperProps {
  steps: StepsProps[];
}

const EvaluateStepper = ({ steps }: EvaluateStepperProps) => {
  const location = useLocation();

  const activeStep = steps.findIndex(
    (step) =>
      step.paths?.includes(location.pathname) ||
      step.path === location.pathname,
  );

  return (
    <Style.Wrapper>
      <Stepper
        style={{ marginTop: '120px', width: '600px', background: '#f8f8f8' }}
        activeStep={activeStep}
      >
        {steps.map((step) => (
          <Step key={step.label}>
            <Style.StepLabel />
          </Step>
        ))}
      </Stepper>
    </Style.Wrapper>
  );
};

export default EvaluateStepper;
