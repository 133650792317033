import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  DSThemeProvider,
  defaultTheme,
} from '@sagacarmi/seminovos-design-system/core/theme';
import LoadingSteps from './components/loadingSteps';
import SopContext from 'components/src/context/SopContext';
import moment from 'moment';
import { Suspense, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, useRoutes, useSearchParams } from 'react-router-dom';
import routes from './config/routes';
import VehicleEvaluationContext from './context/EvaluationContext';
import FinancingContext from './context/FinancingContext';
import './global/GlobalStyles.css';

function App() {
  const queryClient = new QueryClient();

  const locale = 'pt-br';

  moment.locale(locale);

  return (
    <QueryClientProvider client={queryClient}>
      <DSThemeProvider theme={defaultTheme}>
        <div className="App">
          <BrowserRouter>
            <VehicleEvaluationContext>
              <FinancingContext>
                <SopContext>
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    locale={locale}
                    libInstance={moment}
                  >
                    <Suspense fallback={<LoadingSteps />}>
                      <Routers />
                    </Suspense>
                  </MuiPickersUtilsProvider>
                </SopContext>
              </FinancingContext>
            </VehicleEvaluationContext>
          </BrowserRouter>
        </div>
      </DSThemeProvider>
    </QueryClientProvider>
  );
}

function Routers() {
  const element = useRoutes([...routes]);

  const [searchParams] = useSearchParams();

  const setTotemType = () => {
    const queryParamTotemType = searchParams.get('totemType');
    if (['CLEAN', 'TOTEM', 'TOTEM_AFTER_SALE'].includes(queryParamTotemType)) {
      if (queryParamTotemType === 'CLEAN') {
        localStorage.removeItem('totemType');
        return;
      }
      localStorage.setItem('totemType', queryParamTotemType);
    }
  };

  const setMobiId = () => {
    const queryParamTotemType = searchParams.get('mobiId');
    if (queryParamTotemType === 'CLEAN') {
      localStorage.removeItem('mobiId');
      return;
    }
    queryParamTotemType !== null &&
      localStorage.setItem('mobiId', queryParamTotemType);
  };

  useEffect(() => {
    setTotemType();
    setMobiId();
  }, []);

  return element;
}

export default App;
