import { makeStyles, styled } from '@material-ui/core/styles'

export const WrapperLogo = styled('div')({
    display: 'flex', 
    alignItems: 'center', 
    justifyContent:'center', 
    height: '100svh'
})

export const AnimatedLoad = makeStyles(() => ({
    spin: {
      animation: `$spinning 2.5s linear infinite`
    },
  
    '@keyframes spinning': {
      '0%': {
        transform: 'rotate(0)'
      },
      '100%': {
        transform: 'rotate(360deg)'
      }
    },
  }))