import React from 'react';
import { FormikProps } from 'formik';
import useEvaluationSchema, {
  initialValues,
  ValuesEvaluation,
} from '../components/hooks/useEvaluationSchema';
import { EvaluationResponse } from '@components/src/services/lead';

interface IVehicleEvaluation {
  formikVehicle: Partial<FormikProps<ValuesEvaluation>>;
  handleClearFormik: () => void;
  evaluationData: EvaluationResponse;
  setEvaluationData: (value: EvaluationResponse) => void;
}

const VehicleEvaluationContext = React.createContext({} as IVehicleEvaluation);

const VehicleEvaluationProvider = ({ children }: any) => {
  const formikVehicle = useEvaluationSchema(() => console.log());

  const [evaluationData, setEvaluationData] =
    React.useState<EvaluationResponse>(null);

  const handleClearFormik = () => {
    formikVehicle.setValues(initialValues);
  };

  return (
    <VehicleEvaluationContext.Provider
      value={{
        formikVehicle,
        handleClearFormik,
        evaluationData,
        setEvaluationData,
      }}
    >
      {children}
    </VehicleEvaluationContext.Provider>
  );
};

const withVehicleEvaluationContext = (Component: any) => (props: any) =>
  (
    <VehicleEvaluationProvider>
      <Component {...props} />
    </VehicleEvaluationProvider>
  );

const useVehicleEvaluation = () => React.useContext(VehicleEvaluationContext);

export {
  useVehicleEvaluation,
  withVehicleEvaluationContext,
  VehicleEvaluationContext,
  VehicleEvaluationProvider as default,
};
