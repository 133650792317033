import { StepLabel as StepLabelMui, styled } from '@material-ui/core';
import { StyledThemeProp } from '@sagacarmi/seminovos-design-system/core/theme';

export const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  background: 'transparent',
});

export const StepLabel = styled(StepLabelMui)(
  ({ theme: { palette } }: StyledThemeProp) => ({
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      color: palette.brand.primary.dark.dark1,
      border: 'none',
      '& .MuiStepIcon-text': {
        fill: palette.brand.secondary.dark,
      },
    },

    '& .MuiStepIcon-root.MuiStepIcon-completed': {
      color: palette.brand.primary.dark.dark1,
      background: palette.brand.secondary.dark,
      borderRadius: '100%',
      border: 'none',
    },

    '& .MuiStepIcon-text': {
      fill: '#8E8E8E',
    },

    '& .MuiStepIcon-root': {
      color: 'white',
      border: '2px solid #8E8E8E',
      borderRadius: '100%',
      fontSize: '60px',
    },
  }),
);
