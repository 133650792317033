import { useFormik } from 'formik';
import * as yup from 'yup';

interface ValuesFinancing {
  vehiclePrice: number;
  vehicleDownPayment: number;
  months: number;
}

const initialValuesFinancing = {
  vehiclePrice: null,
  vehicleDownPayment: 0,
  months: 48,
};

const useFinancingForm = (submitForm?) => {
  const schema = yup.object({
    vehiclePrice: yup.number().required('Valor do veículo é obrigatório'),
    vehicleDownPayment: yup.number(),
    months: yup.string(),
  });

  const { values, touched, errors, handleSubmit, ...rest } = useFormik({
    initialValues: initialValuesFinancing,
    validationSchema: schema,
    onSubmit: (values) => {
      submitForm(values);
    },
    enableReinitialize: true,
    validateOnMount: true,
  });

  return { values, touched, errors, handleSubmit, ...rest };
};

export { useFinancingForm as default, initialValuesFinancing };
export type { ValuesFinancing };
