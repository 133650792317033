/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import useDebounce from 'components/src/hooks/useDebounce';
import { useNavigate } from 'react-router-dom';

import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import * as Style from './styles';
import { ReactComponent as SearchIcon } from 'components/src/assets/icons/search.svg';
import SearchServices from 'components/src/services/search';
import { useSop } from 'components/src/context/SopContext';

const SearchInput = () => {
  const { handleChangeSop }: any = useSop();

  const [search, setSearch] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [searchIsOpen, setSearchIsOpen] = React.useState<boolean>(false);
  const [searchMakes, setSearchMakes] = React.useState<any>(null);
  const [searchModels, setSearchModels] = React.useState<any>(null);

  const debouncedSearch = useDebounce<string>(search);

  const handleInputChange = (value: string) => {
    setSearch(value);
  };

  const getMakesAndModels = async () => {
    setLoading(true);

    const makesData: any = await SearchServices.makesSearch(search);

    const modelsData: any = await SearchServices.modelsSearch(search);

    setSearchMakes(makesData?.data);

    setSearchModels(modelsData?.data);

    setLoading(false);
  };

  React.useEffect(() => {
    if (debouncedSearch) {
      getMakesAndModels();
    }
  }, [debouncedSearch]);

  const navigate = useNavigate();
  const classes = Style.useStyles();

  return (
    <ClickAwayListener onClickAway={() => setSearchIsOpen(false)}>
      <Style.SearchInputWrapper>
        <Style.InputSearch
          fullWidth
          value={search}
          variant="outlined"
          placeholder="Busque por veículos, marcas ou modelos"
          onChange={(event) => handleInputChange(event.target.value)}
          onClick={() => setSearchIsOpen(true)}
          InputProps={{
            classes,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ width: 21, height: 21 }} />
              </InputAdornment>
            ),
          }}
        />
        {searchIsOpen && search !== '' && (
          <Style.SearchValuesWrapper>
            {loading ? (
              <Style.SearchLoadingCenter>
                <CircularProgress />
              </Style.SearchLoadingCenter>
            ) : (
              <>
                {searchMakes?.length > 0 && (
                  <Style.SearchTag>Marcas</Style.SearchTag>
                )}
                {searchMakes?.map((make: any) => (
                  <Style.SearchValuesItem
                    key={make?.id}
                    onClick={() => {
                      handleChangeSop('ops_', make?.name, true, {
                        id: make?.name,
                        name: make?.name,
                      });
                      navigate('/ofertas/seminovos');
                    }}
                  >
                    {make?.name}
                  </Style.SearchValuesItem>
                ))}
                {searchModels?.length > 0 && (
                  <Style.SearchTag>Modelos</Style.SearchTag>
                )}
                {searchModels?.map((model: any) => (
                  <Style.SearchValuesItem
                    key={model?.id}
                    onClick={() => {
                      handleChangeSop('ops_', model?.name, false, {
                        id: model?.name,
                        name: model?.name,
                      });
                      navigate('/ofertas/seminovos');
                    }}
                  >
                    {model?.name}
                  </Style.SearchValuesItem>
                ))}
              </>
            )}
          </Style.SearchValuesWrapper>
        )}
      </Style.SearchInputWrapper>
    </ClickAwayListener>
  );
};

export default SearchInput;
