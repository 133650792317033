/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Option } from '@sagacarmi/seminovos-design-system/core/inputs/autocomplete';
import { plate } from 'components/src/helpers/regex';

interface ValuesEvaluation {
  plate: string;
  km: number | string;
  make: Option | null;
  model: Option | null;
  modelYear: Option | null;
  trim: Option | null;
  productionYear?: Option | null;
  fuel?: Option | null;
  color?: Option | null;
  clientName: string;
  clientPhone: number | string;
}

const initialValues = {
  plate: '',
  km: '',
  make: null,
  model: null,
  modelYear: null,
  trim: null,
  productionYear: null,
  fuel: null,
  color: null,
  clientName: '',
  clientPhone: '',
};

const useEvaluationForm = (submitForm) => {
  const schema = yup.object({
    plate: yup.string().matches(plate, 'Placa inválida.').optional().nullable(),
    km: yup.string().required(),
    make: yup
      .object()
      .shape({
        value: yup.number(),
        label: yup.string().required(),
      })
      .nullable(),
    model: yup
      .object()
      .shape({
        value: yup.number(),
        label: yup.string().required(),
      })
      .nullable(),
    modelYear: yup
      .object()
      .shape({
        value: yup.number(),
        label: yup.string().required(),
      })
      .nullable(),
    trim: yup
      .object()
      .shape({
        value: yup.number(),
        label: yup.string().required(),
      })
      .nullable(),
    clientName: yup.string().required(),
    clientPhone: yup.string().required(),
    productionYear: yup
      .object()
      .shape({
        value: yup.number(),
        label: yup.string(),
      })
      .nullable(),
    fuel: yup
      .object()
      .shape({
        value: yup.number(),
        label: yup.string(),
      })
      .nullable(),
    color: yup
      .object()
      .shape({
        value: yup.number(),
        label: yup.string(),
      })
      .nullable(),
  });

  const { values, touched, errors, handleSubmit, ...rest } = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      submitForm(values);
    },
    enableReinitialize: true,
    validateOnMount: true,
  });

  return { values, touched, errors, handleSubmit, ...rest };
};

export { useEvaluationForm as default, initialValues };
export type { ValuesEvaluation };
