import images from 'components/src/constants/images';
import LinkComponent from '../Link';
import SearchInput from '../SearchInput';
import * as Style from './styles';

const Header = () => {
  const { LogoSvg } = images;
  return (
    <Style.HeaderWrapper>
      <Style.LogoWrapper>
        <LinkComponent route="/">
          <LogoSvg />
        </LinkComponent>
      </Style.LogoWrapper>
      <SearchInput />
    </Style.HeaderWrapper>
  );
};

export default Header;
