import Logo from '../../assets/icon/logo.png'
import * as Style from './style'


const LoadingSteps = () => {
    const animation = Style.AnimatedLoad()

  return (
    <Style.WrapperLogo>
        <img 
            src={Logo} 
            alt="Primeira mão" 
            className={animation.spin} 
            style={{
                height: '90px', 
                width: '90px',
            }} 
        />
    </Style.WrapperLogo>
  )
}

export default LoadingSteps